.App {
  color: #fff;
}



/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/* 
.App-link {
  color: #61dafb;
} */



/* My css */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF Pro Display', sans-serif;
  color:#fff;
  
}
body{
  
  background-color: #292828;
}

.bold{
  font-weight: 700;
}

.text-dark{
  color: #D0CBCB;
}
.container{
  width: 100%;
  height: auto;
}

#sitename{
  font-weight:700;
  font-size: 35px;
}


.navbar{
  display: flex;
  top: 0;
  width: 100%;
  height: auto;
  background-color: #111011;
  padding: 20px 35px;
}

.edvora_name{
  float: left;
}

.user-info{
  width: 20%;
  display: flex;
  float: right;
  align-items: center;
}

.user-name{
  float: left;
}

.user-name p{
  font-weight: 700;
  font-size: 20px;
}

.user-photo{
  margin-left: 25px;
  float: right;
}

.navbar .user-info .user-photo img{
  vertical-align: middle;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

/* Navbar rends */

#main-box{
  background-color: #292828;
}

.outer-box{
  padding: 29px 43px;
}

.top-box{
  display: flex;
}

.varities{
  display: flex;
}

.varities a{
  text-decoration: none;
  font-size: 18px;
  padding-right: 44px;
}

.varities .selected{
  text-decoration: underline;
  
}

/* varities done */
.rides{
  margin-top:20px ;
  height: 100%;
}

.boxes{
  border-radius: 10px;
  background-color: #171616;
  margin: 13px 0;
}

.all-data{
  padding: 25px;
  display: flex;
  position: relative;
}

.ride-map img{
  width: 296px;
  height: 148px;
  float: left;
  border-radius:5px;
}

.ride-info{
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:0 25px;    
}

.ride-info p{
  font-weight: 500;
  color: #D0CBCB;
  letter-spacing: 1.5px;
  word-spacing: 2px;
}
.ride-info span{
  color: #fff;
}

.curly{
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  background-color: #000;
  padding: 5px 13px;
  height: auto;
  justify-content: center;
  
}
.city-name{

  position: absolute;
  top: 25px;
  right: 180px;

}

.state-name{

  position: absolute;
  top: 25px;
  right: 50px;

}


@media (max-width:768px) {
  
    .boxes{
      max-width:350px;
      align-: center;
    }

    .all-data{
      flex-direction: column;
    }

    .ride-map{
      margin-bottom: 25px;
    }

    .ride-info{
      padding-left: 5px;
    }
    .city-name{
        top:30px;
        right: 40px;
    }

    .state-name{
      
      top:60px;
      right: 40px;
      
    }

    html{
      min-width: 470px;
      overflow: auto;
  }
}